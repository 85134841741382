import React from 'react';
//styles
import { colors, device } from './GlobalStyles';
import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import arrow from '../images/icons/black-arrow-right.svg';

const StyledPanel = styled.section`
    margin: 5em 1em 1em 1em;
    .learn-button {
        border: 1px solid ${colors.grey};
        padding: 1.5em 3em;
        margin-bottom: 4em;
        &:hover {
            cursor: pointer;
            background: ${colors.grey};
            color: ${colors.white};
        }
    }
    .image {
        margin-bottom: -1.5em;
    }
    position: relative;
    .arrow-button {
        position: relative;
        padding: 1em;
        bottom: 4.1em;
        left: 21.7em;
        background: ${colors.yellow};
        border: none;
        cursor: pointer;
    }
    h5 {
        letter-spacing: 3px;
        font-size: 11px;
        font-weight: bold;
        margin-bottom: 0em;
    }
    h3 {
        margin-bottom: 2em;
    }
    @media (min-width: 400px) and (max-width: 500px) {
        .arrow-button {
            left: 24.6em;
            bottom: 4.1em;
        }
        p {
            font-size: 21px;
            line-height: 34px;
        }
    }
    @media ${device.tablet} {
        padding-top: 8em;
        .button-container {
            display: flex;
            margin-top: 2em;
            justify-content: center;
        }
        .cards {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }
        h5 {
            font-size: 16px;
            color: #3d4849;
        }
        h4 {
            margin: 1em 0em;
        }
        h2 {
            margin-bottom: 0.5em;
        }
        p {
            margin-bottom: 1em;
        }
        .service-card {
            width: 600px;
            max-height: 600px;
        }
        .arrow-button {
            left: 40.6em;
            bottom: 4.3em;
        }
    }
    @media ${device.laptop} {
        max-width: 1260px;
        margin: 0 auto;

        .cards {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }
    }
`;

interface Props {
    mdx: string;
}
export const ServicesPanel: React.FC<Props> = ({ mdx }) => {
    return (
        <div className="container">
            <StyledPanel>
                <div className="text">
                    <h4>OUR WORKS</h4>
                    <h2>All the Services we offer.</h2>
                    <p>
                        Through our process, we collaborate with each patient to gain an
                        understanding of their unique needs – defining the success of each
                        treatement
                    </p>
                </div>
                <div className="cards">
                    {mdx.nodes.map(service => (
                        <div key={service.slug} className="service-card">
                            <div className="image">
                                <GatsbyImage
                                    alt={service.slug}
                                    image={
                                        // eslint-disable-next-line prettier/prettier
                                    service.frontmatter.featuredImage.childImageSharp.gatsbyImageData
                                    }
                                />
                                <Link to={`/${service.frontmatter.slug}`}>
                                    <button className="arrow-button">
                                        <img src={arrow} alt="black arrow pointing right" />
                                    </button>
                                </Link>
                            </div>
                            <h5>{service.frontmatter.type}</h5>
                            <h3>{service.frontmatter.title}</h3>
                        </div>
                    ))}
                </div>
                {/* <div className="button-container">
                    <button className="learn-button">Load More</button>
                </div> */}
            </StyledPanel>
        </div>
    );
};
